// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (min-width: 768px) {
    .name {
        display: flex;
        gap: 1rem;
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: calc(33%);
    }
}

@media (max-width: 767px) {
    .name {
        display: flex;
        flex-direction: column;
        flex: 1 1;
    }

    .name .name-box {
        flex: none;
    }

}

.name .name-box {
    min-width: 0;
    /* Prevent overflow caused by long content */
}

.container {
    background-color: white;
    margin-top: 50px !important;
    flex-direction: column;
}

@media (min-width: 768px) {
    .container {
        width: 960px !important;
        display: flex;
        margin-bottom: 10%;
    }
}

.loginForm {
    margin-top: 40px;
    display: block;
}

.loading-spinner {
    margin-top: 20px;
}

.buttons {
    margin-top: 60px;
}

@media (max-width: 768px) {
    .row {
        flex-direction: row;
        gap: 1rem;
    }

    .buttons {
        margin-top: 20px;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/LoginPage/LoginPage.css"],"names":[],"mappings":"AAAA;IACI;QACI,aAAa;QACb,SAAS;QACT,YAAmB;QAAnB,cAAmB;QAAnB,qBAAmB;IACvB;AACJ;;AAEA;IACI;QACI,aAAa;QACb,sBAAsB;QACtB,SAAO;IACX;;IAEA;QACI,UAAU;IACd;;AAEJ;;AAEA;IACI,YAAY;IACZ,4CAA4C;AAChD;;AAEA;IACI,uBAAuB;IACvB,2BAA2B;IAC3B,sBAAsB;AAC1B;;AAEA;IACI;QACI,uBAAuB;QACvB,aAAa;QACb,kBAAkB;IACtB;AACJ;;AAEA;IACI,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI;QACI,mBAAmB;QACnB,SAAS;IACb;;IAEA;QACI,gBAAgB;IACpB;AACJ","sourcesContent":["@media (min-width: 768px) {\n    .name {\n        display: flex;\n        gap: 1rem;\n        flex: 1 0 calc(33%);\n    }\n}\n\n@media (max-width: 767px) {\n    .name {\n        display: flex;\n        flex-direction: column;\n        flex: 1;\n    }\n\n    .name .name-box {\n        flex: none;\n    }\n\n}\n\n.name .name-box {\n    min-width: 0;\n    /* Prevent overflow caused by long content */\n}\n\n.container {\n    background-color: white;\n    margin-top: 50px !important;\n    flex-direction: column;\n}\n\n@media (min-width: 768px) {\n    .container {\n        width: 960px !important;\n        display: flex;\n        margin-bottom: 10%;\n    }\n}\n\n.loginForm {\n    margin-top: 40px;\n    display: block;\n}\n\n.loading-spinner {\n    margin-top: 20px;\n}\n\n.buttons {\n    margin-top: 60px;\n}\n\n@media (max-width: 768px) {\n    .row {\n        flex-direction: row;\n        gap: 1rem;\n    }\n\n    .buttons {\n        margin-top: 20px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
