import React, {FC} from "react";
import "./Home.css";
import {FirstSection} from "../../components/FirstSection";
import {useNavigate} from "react-router-dom";
import SecondSection from "../../components/SecondSection/SecondSection";
import ThirdSection from "../../components/ThirdSection/ThirdSection";
import {Header} from "../../components/Header";
import {Carousel} from "../../components/Carousel";

const Home: FC = () => {
    const navigate = useNavigate();

    return (
        <div style={{width: "100%"}}>
            <Header currentPage="accueil"/>
            <div className="fix-div">

                <div style={{marginRight: "0"}}>
                    <button type="button" className="btn btn-light bouton"
                            onClick={() => navigate("/form")}>Inscriptions
                    </button>
                </div>
                <div style={{display: "flex", flexDirection: "column", gap: "2rem"}}>
                    <Carousel/>
                    <FirstSection/>
                    <SecondSection/>
                    <ThirdSection/>
                </div>
            </div>
        </div>

    )
}

export default Home;