// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bouton {
    position: absolute;
    right: calc(10vw);
    top: 10px;
}

.fix-div {
    gap: 3rem;
    flex-direction: column;
    justify-content: center;
    background-color: white
}

@media (min-width: 768px) {
    .fix-div {
        width: 80%;
        margin: 0 auto;
        display: flex;
    }
}

@media (max-width: 767px) {
    .img-container {
        display: block;
        gap: 15rem;
        max-width: 90%;
        margin-top: 50px;
    }
}

@media (min-width: 768px) {
    .img-container {
        display: block;
        gap: 15rem;
        max-width: 80%;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/Home/Home.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,SAAS;AACb;;AAEA;IACI,SAAS;IACT,sBAAsB;IACtB,uBAAuB;IACvB;AACJ;;AAEA;IACI;QACI,UAAU;QACV,cAAc;QACd,aAAa;IACjB;AACJ;;AAEA;IACI;QACI,cAAc;QACd,UAAU;QACV,cAAc;QACd,gBAAgB;IACpB;AACJ;;AAEA;IACI;QACI,cAAc;QACd,UAAU;QACV,cAAc;IAClB;AACJ","sourcesContent":[".bouton {\n    position: absolute;\n    right: calc(10vw);\n    top: 10px;\n}\n\n.fix-div {\n    gap: 3rem;\n    flex-direction: column;\n    justify-content: center;\n    background-color: white\n}\n\n@media (min-width: 768px) {\n    .fix-div {\n        width: 80%;\n        margin: 0 auto;\n        display: flex;\n    }\n}\n\n@media (max-width: 767px) {\n    .img-container {\n        display: block;\n        gap: 15rem;\n        max-width: 90%;\n        margin-top: 50px;\n    }\n}\n\n@media (min-width: 768px) {\n    .img-container {\n        display: block;\n        gap: 15rem;\n        max-width: 80%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
