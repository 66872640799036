// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-container {
    z-index: 999;
}

.sticky {
    position: fixed !important;
    top: 0;
    width: 100%
}

.sticky + .content {
    padding-top: 102px;
}`, "",{"version":3,"sources":["webpack://./src/components/Header/Header.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,0BAA0B;IAC1B,MAAM;IACN;AACJ;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".header-container {\n    z-index: 999;\n}\n\n.sticky {\n    position: fixed !important;\n    top: 0;\n    width: 100%\n}\n\n.sticky + .content {\n    padding-top: 102px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
