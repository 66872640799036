import {Header} from "../../components/Header";
import {Form} from "../../components/Form";
import React, {FC} from "react";

const FormulaireInscription: FC = () => {
    return (
        <div style={{width: "100%"}}>
            <Header currentPage="inscriptions"/>
            <Form/>
        </div>

    )
}

export default FormulaireInscription;