import React, { ReactNode } from "react";
import { useSession } from "../SessionContextProvider/SessionContextProvider";
import { Navigate, useLocation } from "react-router-dom";
import { makeRequestForStatusCode } from "../../helpers/makeRequests";

const isRefreshed = await makeRequestForStatusCode("refresh");

const ProtectedRoute: React.FC<{ children: ReactNode }> = ({ children }) => {
    const { isAuthenticated } = useSession();
    const location = useLocation();


    if (!isAuthenticated) {
        if (isRefreshed !== 200) {
            return <Navigate to="/login" state={{ from: location }} replace />;
        }
    }
    return <>{children}</>;
}

export default ProtectedRoute;
