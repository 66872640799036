import React, {ChangeEvent, FC, useEffect, useState} from "react";
import {Session} from "../../interfaces/session";
import {localiseDateString} from "../../helpers/processInput";
import {getNbrInscrits} from "../../helpers/getInscrits";
import {Row} from "../../interfaces/row";

interface SessionsProps {
    sessions: Session[];
    handleDelete: (key: number) => void;
    handleCreate: (place: string, nickname: string, dateStart: Date, dateEnd: Date) => void;
    rows: Row[];
}

const SessionsTable: FC<SessionsProps> = ({sessions, handleDelete, handleCreate, rows}) => {

    const [nbInscrits, setNbInscrits] = useState(new Map());
    const [startDate, setStartDate] = useState<Date>(new Date());
    const [endDate, setEndDate] = useState<Date>(new Date());
    const [place, setPlace] = useState<string>("");
    const [nickname, setNickname] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);


    useEffect(() => {
        if (sessions.length > 0) {
            const getSession = async (id: string) => {
                const count = await getNbrInscrits(id);
                setNbInscrits((prevMap) => {
                    const newMap = new Map(prevMap);
                    newMap.set(id, count);
                    return newMap;
                });
            }
            sessions.map((session) => {
                getSession(session.id);
            });
        }

    }, [sessions]);

    const handleStartDateChange = (event: ChangeEvent<HTMLInputElement>) => {
        setStartDate(new Date(event.target.value));

    }

    const handleEndDateChange = (event: ChangeEvent<HTMLInputElement>) => {
        setEndDate(new Date(event.target.value));
    }

    const handlePlaceChange = (event: ChangeEvent<HTMLInputElement>) => {
        setPlace(event.target.value);
    }

    const handleNicknameChange = (event: ChangeEvent<HTMLInputElement>) => {
        setNickname(event.target.value);
    }

    const callbackCreate = (place: string, nickname: string, dateStart: Date, dateEnd: Date) => {
        setIsLoading(true);
        handleCreate(place, nickname, dateStart, dateEnd);
        setIsLoading(false);
    }

    return (
        <table id="table" className="table table-striped table-responsive table-bis" style={{overflowX: "scroll"}}>
            <thead>
            <tr>
                <th scope="col">#</th>
                <th scope="col">Lieu</th>
                <th scope="col">Surnom</th>
                <th scope="col">Date de début</th>
                <th scope="col">Date de fin</th>
                <th scope="col">Nombre d'inscrits</th>
                <th scope="col"></th>
            </tr>
            </thead>
            <tbody>
            {sessions.length > 0 && sessions.map((session, index) => (
                <tr key={index}>
                    <th scope="row">{index + 1}</th>
                    <td>{session.place}</td>
                    <td>{session.nickname}</td>
                    <td>{localiseDateString(session.date_start.toString())}</td>
                    <td>{localiseDateString(session.date_end.toString())}</td>
                    <td>{nbInscrits.get(session.id)}</td>
                    <td>
                        <div style={{display: "flex", gap: "1rem"}}>
                            <button type="button" className="btn btn-outline-danger gap-2 d-flex" onClick={() => {
                                setIsLoading(true);
                                handleDelete(index);
                                setIsLoading(false);
                            }}>
                                <i className="bi bi-dash-circle"></i>
                                Supprimer
                            </button>
                            <div id="loading" className="spinner-border spinner-bis" role="status" hidden={!isLoading}/>
                        </div>
                    </td>
                </tr>
            ))}
            {rows.length > 0 && (rows.map((row, index) => (
                <tr key={row.id}>
                    <th scope="row"></th>
                    <td><input type="text" id={row.placeFieldId} aria-describedby="placeHelp"
                               onChange={handlePlaceChange} required/></td>
                    <td><input type="text" id={row.nicknameFieldId} aria-describedby="placeHelp"
                               onChange={handleNicknameChange} required/></td>
                    <td><input type="date" id={row.dateStartFieldId} aria-describedby="placeHelp"
                               min={new Date().toISOString().split('T')[0]} onChange={handleStartDateChange} required/>
                    </td>
                    <td><input type="date" id={row.dateEndFieldId} aria-describedby="placeHelp"
                               min={startDate.toISOString().split('T')[0]} onChange={handleEndDateChange} required/>
                    </td>
                    <td>0</td>
                    <td>
                        <div style={{display: "flex", gap: "1rem"}}>
                            <button type="button" className="btn btn-success gap-2 d-flex"
                                    onClick={() => callbackCreate(place, nickname, startDate, endDate)}>
                                <i className="bi bi-plus-circle"></i>
                                Ajouter
                            </button>
                            <div id="loading" className="spinner-border spinner-bis" role="status" hidden={!isLoading}/>
                        </div>
                    </td>
                </tr>
            )))}
            </tbody>
        </table>
    );
}

export default SessionsTable;