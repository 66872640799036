// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mapstyle {
    display: flex;
    width: 95%;
    height: 20vh;
    margin-left: calc(2.5vw);
    margin-bottom: calc(2.5vw);
}

.third-section {
    margin-left: calc(2.5vw);
}`, "",{"version":3,"sources":["webpack://./src/components/ThirdSection/ThirdSection.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,UAAU;IACV,YAAY;IACZ,wBAAwB;IACxB,0BAA0B;AAC9B;;AAEA;IACI,wBAAwB;AAC5B","sourcesContent":[".mapstyle {\n    display: flex;\n    width: 95%;\n    height: 20vh;\n    margin-left: calc(2.5vw);\n    margin-bottom: calc(2.5vw);\n}\n\n.third-section {\n    margin-left: calc(2.5vw);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
