// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (min-width: 768px) {
    .name {
        display: flex;
        gap: 1rem;
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: calc(33%);
    }
}

@media (max-width: 767px) {
    .name {
        display: flex;
        flex-direction: column;
        flex: 1 1;
    }

    .name .name-box {
        flex: none;
    }

}

.name .name-box {
    min-width: 0;
    /* Prevent overflow caused by long content */
}

.container {
    background-color: white;
}

@media (min-width: 768px) {
    .container {
        width: 960px !important;
        display: flex;
        margin-bottom: 10%;
    }
}


.form {
    flex: 1 1;
}`, "",{"version":3,"sources":["webpack://./src/components/Form/Form.css"],"names":[],"mappings":"AAAA;IACI;QACI,aAAa;QACb,SAAS;QACT,YAAmB;QAAnB,cAAmB;QAAnB,qBAAmB;IACvB;AACJ;;AAEA;IACI;QACI,aAAa;QACb,sBAAsB;QACtB,SAAO;IACX;;IAEA;QACI,UAAU;IACd;;AAEJ;;AAEA;IACI,YAAY;IACZ,4CAA4C;AAChD;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI;QACI,uBAAuB;QACvB,aAAa;QACb,kBAAkB;IACtB;AACJ;;;AAGA;IACI,SAAO;AACX","sourcesContent":["@media (min-width: 768px) {\n    .name {\n        display: flex;\n        gap: 1rem;\n        flex: 1 0 calc(33%);\n    }\n}\n\n@media (max-width: 767px) {\n    .name {\n        display: flex;\n        flex-direction: column;\n        flex: 1;\n    }\n\n    .name .name-box {\n        flex: none;\n    }\n\n}\n\n.name .name-box {\n    min-width: 0;\n    /* Prevent overflow caused by long content */\n}\n\n.container {\n    background-color: white;\n}\n\n@media (min-width: 768px) {\n    .container {\n        width: 960px !important;\n        display: flex;\n        margin-bottom: 10%;\n    }\n}\n\n\n.form {\n    flex: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
