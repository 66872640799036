import React, { FC } from "react";

interface NationalityPickerProps {
    bootstrapClass: string;
    setNationality: (e: string) => void;
}

const NationalityPicker: FC<NationalityPickerProps> = (props: NationalityPickerProps) => {
    const className = `form-group ${props.bootstrapClass}`;

    return (
        <div className={className}>
            <label htmlFor="nationality" className="form-label">Nationalité</label>
            <select id="inputState" className="form-control" onChange={(e) => props.setNationality((e.target as HTMLSelectElement).value)}>
                <option value="Camerounaise">Camerounaise</option>
                <option value="Afghane">Afghane</option>
                <option value="Albanaise">Albanaise</option>
                <option value="Algérienne">Algérienne</option>
                <option value="Allemande">Allemande</option>
                <option value="Américaine">Américaine</option>
                <option value="Angolaise">Angolaise</option>
                <option value="Argentine">Argentine</option>
                <option value="Arménienne">Arménienne</option>
                <option value="Australienne">Australienne</option>
                <option value="Autrichienne">Autrichienne</option>
                <option value="Azerbaïdjanaise">Azerbaïdjanaise</option>
                <option value="Bangladaise">Bangladaise</option>
                <option value="Belge">Belge</option>
                <option value="Béninoise">Béninoise</option>
                <option value="Bhoutanaise">Bhoutanaise</option>
                <option value="Bolivienne">Bolivienne</option>
                <option value="Bosniaque">Bosniaque</option>
                <option value="Botswanaise">Botswanaise</option>
                <option value="Brésilienne">Brésilienne</option>
                <option value="Britannique">Britannique</option>
                <option value="Bulgare">Bulgare</option>
                <option value="Burkinabè">Burkinabè</option>
                <option value="Burundaise">Burundaise</option>
                <option value="Canadienne">Canadienne</option>
                <option value="Cap-verdienne">Cap-verdienne</option>
                <option value="Centrafricaine">Centrafricaine</option>
                <option value="Chilienne">Chilienne</option>
                <option value="Chinoise">Chinoise</option>
                <option value="Chypriote">Chypriote</option>
                <option value="Colombienne">Colombienne</option>
                <option value="Comorienne">Comorienne</option>
                <option value="Congolaise (Congo)">Congolaise (Congo)</option>
                <option value="Congolaise (RDC)">Congolaise (RDC)</option>
                <option value="Costaricaine">Costaricaine</option>
                <option value="Croate">Croate</option>
                <option value="Cubaine">Cubaine</option>
                <option value="Danoise">Danoise</option>
                <option value="Djiboutienne">Djiboutienne</option>
                <option value="Dominicaine">Dominicaine</option>
                <option value="Égyptienne">Égyptienne</option>
                <option value="Émiratie">Émiratie</option>
                <option value="Équato-guinéenne">Équato-guinéenne</option>
                <option value="Équatorienne">Équatorienne</option>
                <option value="Espagnole">Espagnole</option>
                <option value="Estonienne">Estonienne</option>
                <option value="Éthiopienne">Éthiopienne</option>
                <option value="Fidjienne">Fidjienne</option>
                <option value="Finlandaise">Finlandaise</option>
                <option value="Française">Française</option>
                <option value="Gabonaise">Gabonaise</option>
                <option value="Gambienne">Gambienne</option>
                <option value="Géorgienne">Géorgienne</option>
                <option value="Ghanéenne">Ghanéenne</option>
                <option value="Grecque">Grecque</option>
                <option value="Guatémaltèque">Guatémaltèque</option>
                <option value="Guinéenne">Guinéenne</option>
                <option value="Guinéenne-Bissau">Guinéenne-Bissau</option>
                <option value="Guyanienne">Guyanienne</option>
                <option value="Haïtienne">Haïtienne</option>
                <option value="Hondurienne">Hondurienne</option>
                <option value="Hongroise">Hongroise</option>
                <option value="Indienne">Indienne</option>
                <option value="Indonésienne">Indonésienne</option>
                <option value="Irakienne">Irakienne</option>
                <option value="Iranienne">Iranienne</option>
                <option value="Irlandaise">Irlandaise</option>
                <option value="Islandaise">Islandaise</option>
                <option value="Israélienne">Israélienne</option>
                <option value="Italienne">Italienne</option>
                <option value="Ivoirienne">Ivoirienne</option>
                <option value="Jamaïcaine">Jamaïcaine</option>
                <option value="Japonaise">Japonaise</option>
                <option value="Jordienne">Jordienne</option>
                <option value="Kazakhe">Kazakhe</option>
                <option value="Kényane">Kényane</option>
                <option value="Kirghize">Kirghize</option>
                <option value="Kiribatienne">Kiribatienne</option>
                <option value="Koweïtienne">Koweïtienne</option>
                <option value="Laotienne">Laotienne</option>
                <option value="Lesothane">Lesothane</option>
                <option value="Lettone">Lettone</option>
                <option value="Libanaise">Libanaise</option>
                <option value="Libérienne">Libérienne</option>
                <option value="Libyenne">Libyenne</option>
                <option value="Liechtensteinoise">Liechtensteinoise</option>
                <option value="Lituanienne">Lituanienne</option>
                <option value="Luxembourgeoise">Luxembourgeoise</option>
                <option value="Macédonienne">Macédonienne</option>
                <option value="Malaisienne">Malaisienne</option>
                <option value="Malawienne">Malawienne</option>
                <option value="Maldivienne">Maldivienne</option>
                <option value="Malienne">Malienne</option>
                <option value="Maltaise">Maltaise</option>
                <option value="Marocaine">Marocaine</option>
                <option value="Mauricienne">Mauricienne</option>
                <option value="Mauritanienne">Mauritanienne</option>
                <option value="Mexicaine">Mexicaine</option>
                <option value="Micronésienne">Micronésienne</option>
                <option value="Moldave">Moldave</option>
                <option value="Mongole">Mongole</option>
                <option value="Monténégrine">Monténégrine</option>
                <option value="Mozambicaine">Mozambicaine</option>
                <option value="Namibienne">Namibienne</option>
                <option value="Népalaise">Népalaise</option>
                <option value="Nicaraguayenne">Nicaraguayenne</option>
                <option value="Nigériane">Nigériane</option>
                <option value="Nigérienne">Nigérienne</option>
                <option value="Norvégienne">Norvégienne</option>
                <option value="Néo-Zélandaise">Néo-Zélandaise</option>
                <option value="Omanaise">Omanaise</option>
                <option value="Ougandaise">Ougandaise</option>
                <option value="Ouzbèke">Ouzbèke</option>
                <option value="Pakistanaise">Pakistanaise</option>
                <option value="Palestinienne">Palestinienne</option>
                <option value="Panaméenne">Panaméenne</option>
                <option value="Papouasienne">Papouasienne</option>
                <option value="Paraguayenne">Paraguayenne</option>
                <option value="Néerlandaise">Néerlandaise</option>
                <option value="Péruvienne">Péruvienne</option>
                <option value="Philippine">Philippine</option>
                <option value="Polonaise">Polonaise</option>
                <option value="Portoricaine">Portoricaine</option>
                <option value="Portugaise">Portugaise</option>
                <option value="Qatarienne">Qatarienne</option>
                <option value="Roumaine">Roumaine</option>
                <option value="Russe">Russe</option>
                <option value="Rwandaise">Rwandaise</option>
                <option value="Saint-Marinaise">Saint-Marinaise</option>
                <option value="Saoudienne">Saoudienne</option>
                <option value="Sénégalaise">Sénégalaise</option>
                <option value="Serbe">Serbe</option>
                <option value="Seychelloise">Seychelloise</option>
                <option value="Sierra-Léonaise">Sierra-Léonaise</option>
                <option value="Singapourienne">Singapourienne</option>
                <option value="Slovaque">Slovaque</option>
                <option value="Slovène">Slovène</option>
                <option value="Somalienne">Somalienne</option>
                <option value="Soudanaise">Soudanaise</option>
                <option value="Sri-Lankaise">Sri-Lankaise</option>
                <option value="Suédoise">Suédoise</option>
                <option value="Suisse">Suisse</option>
                <option value="Surinamaise">Surinamaise</option>
                <option value="Swazie">Swazie</option>
                <option value="Syrienne">Syrienne</option>
                <option value="Tadjike">Tadjike</option>
                <option value="Tanzanienne">Tanzanienne</option>
                <option value="Tchadienne">Tchadienne</option>
                <option value="Tchèque">Tchèque</option>
                <option value="Thaïlandaise">Thaïlandaise</option>
                <option value="Togolaise">Togolaise</option>
                <option value="Tongaise">Tongaise</option>
                <option value="Trinidadienne">Trinidadienne</option>
                <option value="Tunisienne">Tunisienne</option>
                <option value="Turkmène">Turkmène</option>
                <option value="Turque">Turque</option>
                <option value="Ukrainienne">Ukrainienne</option>
                <option value="Uruguayenne">Uruguayenne</option>
                <option value="Vanuatuane">Vanuatuane</option>
                <option value="Vénézuélienne">Vénézuélienne</option>
                <option value="Vietnamienne">Vietnamienne</option>
                <option value="Yéménite">Yéménite</option>
                <option value="Zambienne">Zambienne</option>
                <option value="Zimbabwéenne">Zimbabwéenne</option>
            </select>
        </div >
    )
}

export default NationalityPicker;