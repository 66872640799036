import React, {FC} from 'react';

import {CarouselItem} from "../CarouselItem";

const Carousel: FC = () => {
    return (
        <div id="carouselExample" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-inner">
                <CarouselItem url="https://iegeducation-assets.ams3.cdn.digitaloceanspaces.com/rinvindaf.jpg"
                              isActive={true}/>
                <CarouselItem url="https://iegeducation-assets.ams3.cdn.digitaloceanspaces.com/formation-1.jpg"
                              objectPositionOverride="18%"/>
                <CarouselItem url="https://iegeducation-assets.ams3.cdn.digitaloceanspaces.com/formation-2.jpg"/>
                <CarouselItem url="https://iegeducation-assets.ams3.cdn.digitaloceanspaces.com/formation-4.jpg"/>
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExample"
                    data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Précédent</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExample"
                    data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Suivant</span>
            </button>
        </div>
    )
};

export default Carousel;