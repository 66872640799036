import {
  BrowserRouter
} from "react-router-dom";
import "./App.css";
import { PageHandler } from "./routing/page-handler";
import { APIProvider } from '@vis.gl/react-google-maps';
import React from "react";

export default function App() {
  const googleMapsKey = process.env.REACT_APP_GOOGLE_MAPS_KEY ?? "";

  return (
    <div className="App">
      <BrowserRouter basename="/">
        <APIProvider apiKey={googleMapsKey}>
          <PageHandler />
        </APIProvider>
      </BrowserRouter>
    </div>
  );
}