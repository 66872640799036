// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-bis {
    display: flex;
    flex-direction: row; /* This keeps the Sidebar next to the content */
}

.content-container {
    width: 100px;
    display: flex;
    flex-direction: column; /* This ensures the h1 and table stack vertically */
    flex-grow: 1; /* This allows the content-container to take up the remaining space next to the Sidebar */
    gap: 2rem;
}

h1.title {
    margin-top: 20px;
    margin-bottom: 20px;
}

.main-row {
    width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/pages/Tariffs/Tariffs.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB,EAAE,+CAA+C;AACxE;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,sBAAsB,EAAE,mDAAmD;IAC3E,YAAY,EAAE,yFAAyF;IACvG,SAAS;AACb;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,WAAW;AACf","sourcesContent":[".container-bis {\n    display: flex;\n    flex-direction: row; /* This keeps the Sidebar next to the content */\n}\n\n.content-container {\n    width: 100px;\n    display: flex;\n    flex-direction: column; /* This ensures the h1 and table stack vertically */\n    flex-grow: 1; /* This allows the content-container to take up the remaining space next to the Sidebar */\n    gap: 2rem;\n}\n\nh1.title {\n    margin-top: 20px;\n    margin-bottom: 20px;\n}\n\n.main-row {\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
