// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `video {
    position: relative;
    width: 100%;
    height: auto;
    margin-bottom: 30px;
    margin-top: 10px;
}

.second-section {
    max-width: 95%;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.secondsection-text {
    width: -moz-fit-content;
    width: fit-content;
    /* Or a specific width */
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/components/SecondSection/SecondSection.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,iBAAiB;IACjB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;AAC1B;;AAEA;IACI,uBAAkB;IAAlB,kBAAkB;IAClB,wBAAwB;IACxB,gBAAgB;IAChB,mBAAmB;IACnB,kBAAkB;AACtB","sourcesContent":["video {\n    position: relative;\n    width: 100%;\n    height: auto;\n    margin-bottom: 30px;\n    margin-top: 10px;\n}\n\n.second-section {\n    max-width: 95%;\n    margin-left: 10px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n}\n\n.secondsection-text {\n    width: fit-content;\n    /* Or a specific width */\n    margin-top: 20px;\n    margin-bottom: 20px;\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
