import React, {FormEvent, useEffect, useState} from "react";
import {useSession} from "../../components/SessionContextProvider/SessionContextProvider";
import {useLocation, useNavigate} from "react-router-dom";
import "./LoginPage.css";
import {makeCaptchaRequest} from "../../helpers/makeRequests";
import {Captcha} from "../../interfaces/captcha";
import useRecaptcha from "../../hooks/useRecaptcha";

const LoginPage = () => {
    const executeRecaptcha = useRecaptcha();

    const {login, isAuthenticated, isLoginSuccessful} = useSession();
    const [username, setUsername] = useState<string>("");
    const [password, setPassword] = useState<string>("")
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isDanger, setIsDanger] = useState<boolean>(false);
    const location = useLocation();
    const navigate = useNavigate();

    const from = (location.state as { from: Location })?.from?.pathname || '/';

    const handleFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!executeRecaptcha) {
            setIsDanger(true);
            console.log("execute captcha not yet available");
            return;
        }
        setIsDanger(false);
        setIsLoading(true);
        const captchaToken = await executeRecaptcha('login');
        if (captchaToken && captchaToken != "") {
            const tokenValidation: Captcha = await makeCaptchaRequest(captchaToken)
            if (tokenValidation.score >= 0.5) {
                login(username, password);
                setIsLoading(false);
            } else {
                setIsDanger(true);
                setIsLoading(false);
            }
        } else {
            setIsDanger(true);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (!isLoginSuccessful) {
            setIsDanger(true);
        } else {
            setIsDanger(false);
        }
    }, [isLoginSuccessful])

    useEffect(() => {
        if (isAuthenticated) {
            navigate(from, {replace: true});
        }
    }, [isAuthenticated]);

    const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {value} = event.target;
        setUsername(value);
    }

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {value} = event.target;
        setPassword(value);
    }

    return (
        <div className="container">
            <div>
                <h1>Connexion</h1>
            </div>
            <form onSubmit={handleFormSubmit} className="loginForm needs-validation">
                <div className="row">
                    <div className="form-group col-md-6">
                        <input
                            type="text"
                            placeholder="Nom d'utilisateur"
                            className="form-control"
                            value={username}
                            name="username"
                            onChange={handleUsernameChange}
                            required
                        />
                    </div>
                    <div className="form-group col-md-6">
                        <input
                            type="password"
                            placeholder="Mot de passe"
                            className="form-control"
                            value={password}
                            name="password"
                            onChange={handlePasswordChange}
                            required
                        />
                    </div>
                </div>


                <button className="buttons btn btn-primary" type="submit">
                    Connexion
                </button>
            </form>
            <div className="alert alert-danger loading-spinner" role="alert" hidden={!isDanger}>
                Les identifiants entrés sont incorrects.
            </div>
            <div id="loading" className="spinner-border spinner-bis loading-spinner" role="status" hidden={!isLoading}/>
        </div>
    )
}

export default LoginPage;