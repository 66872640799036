import React, {FC} from "react"
import "./ThirdSection.css"
import {formatDate, formatPrice} from "../../helpers/processInput";
import {useGetSessions} from "../../hooks/useGetSessions";
import {useGetPrices} from "../../hooks/useGetPrices";

const ThirdSection: FC = () => {
    const [sessions] = useGetSessions();
    const [fare, paymentMethods] = useGetPrices();


    return <div className="third-section" id="prochaines-dates">
        <h1 className="firstsection-text">Dates des prochaines formations</h1>
        <div style={{marginBottom: "25px"}}>Le prix standard d'une formation RINVINDAF est
            de <b>{formatPrice(fare.standard_fare.eur)} EUR / {formatPrice(fare.standard_fare.xaf)} FCFA</b>
        </div>
        <div style={{marginBottom: "25px"}}>
            <div style={{marginBottom: "15px"}}>Les modalités de paiement sont les suivantes:</div>
            <ul style={{marginBottom: "25px"}}>
                {paymentMethods.length > 0 && paymentMethods.map((paymentMethod) => (
                    <li>{paymentMethod.payment_option} ({paymentMethod.payment_option_description})</li>
                ))}
            </ul>
        </div>
        <div style={{marginBottom: "25px"}}>Voici les dates des prochaines formations:</div>
        <ul style={{marginBottom: "50px"}}>
            {sessions.length > 0 ? sessions.filter((session) => session.is_active).map((session, index) => (
                <li>{session.place} - {formatDate(session.date_start, session.date_end)}</li>
            )) : <li>Aucune formation disponible</li>}
        </ul>
    </div>
}

export default ThirdSection;