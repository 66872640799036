import React, {FC, useState} from "react";
import "./Sessions.css";
import {SessionsTable} from "../../components/SessionsTable";
import {Row} from "../../interfaces/row";
import {handleErrorCode, makeRequest} from "../../helpers/makeRequests";
import {ReturnTypes} from "../../interfaces/returntype";
import {useGetSessions} from "../../hooks/useGetSessions";
import {Sidebar} from "../../components/Sidebar";

const Sessions: FC = () => {

    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [isDanger, setIsDanger] = useState<boolean>(false);
    const [rows, setRows] = useState<Row[]>([]);

    const [sessions, fetchSessions, shouldRefreshSessions] = useGetSessions(() => setIsLoaded(true));

    const handleDelete = async (key: number) => {
        const table: HTMLTableElement = document.getElementById("table") as HTMLTableElement;
        const cells: HTMLCollection = table.rows[key + 1].cells;
        const tableHeader: HTMLTableCellElement = cells[0] as HTMLTableCellElement;
        const rowIndex: string = tableHeader.childNodes[0].nodeValue as string;
        const sessionIndex: string = sessions.at(Number(rowIndex) - 1)?.id as string;
        await deleteSession(sessionIndex);
        shouldRefreshSessions.current = shouldRefreshSessions.current + 1;
        fetchSessions();
    }

    const handleCreate = async (place: string, nickname: string, dateStart: Date, dateEnd: Date) => {
        await createSession(place, nickname, dateStart, dateEnd);
        shouldRefreshSessions.current = shouldRefreshSessions.current + 1;
        fetchSessions();
    }

    const createField = () => {
        const id = Math.ceil(Math.random() * 100);
        const row: Row = {
            id,
            placeFieldId: `place-${id}`,
            nicknameFieldId: `nickname-${id}`,
            dateStartFieldId: `dateStart-${id}`,
            dateEndFieldId: `dateEnd-${id}`
        }
        setRows((prevRows) => {
            return [...prevRows, row];
        });
    }

    const deleteSession = async (sessionKey: string) => {
        try {
            const response = await makeRequest("delete-session", JSON.stringify({"session_id": `${sessionKey}`}), ReturnTypes.String);
            handleErrorCode(response);
        } catch (error) {
            console.error(error);
            setIsDanger(true);
        }
    }

    const createSession = async (place: string, nickname: string, dateStart: Date, dateEnd: Date) => {
        const body = JSON.stringify({
            place,
            nickname,
            "date_start": dateStart,
            "date_end": dateEnd
        });
        try {
            const response = await makeRequest("add-session", body, ReturnTypes.StatusCode);
            if (Array.isArray(response)) {
                const [responseText, statusCode] = response;
                if (statusCode !== 200) {
                    throw new Error(responseText);
                }
            } else {
                throw new Error("exception occurred");
            }
        } catch (error) {
            console.error(error);
            setIsDanger(true);
        }
    }

    return (
        <div className="container-fluid container-bis sessions-max" style={{overflow: "hidden"}}>
            <div className="row flex-nowrap">
                <Sidebar/>
                <div className="col py-3">
                    <div className="main">
                        <div className="table-overflow">
                            <SessionsTable sessions={sessions} handleDelete={handleDelete} handleCreate={handleCreate}
                                           rows={rows}/>
                        </div>
                        <div className="spinner-bis">
                            <div id="loading" className="spinner-border spinner-bis" role="status" hidden={isLoaded}/>
                            <div id="server-error" className="panne" hidden={!isError}>
                                <i className="bi bi-database-x h1"></i>
                                <div>Panne serveur</div>
                            </div>
                        </div>
                        <div style={{marginBottom: "10px", display: "flex", gap: "1rem"}}>
                            <button type="button" className="btn btn-primary gap-2 d-flex" onClick={createField}>
                                <i className="bi bi-plus-circle"></i>
                                Créer
                            </button>
                        </div>
                    </div>
                    <div className="alert alert-danger" role="alert" hidden={!isDanger}>
                        Impossible de compléter cette requête pour le moment.
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Sessions;