// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.panne {
    display: block;
    justify-content: center;
    align-items: center;
}

.gap-2 {
    gap: .2rem !important;
}

.mb-3 {
    margin-bottom: 1rem !important;
}

.d-flex {
    display: flex !important;
}

.sessions-max {
    overflow: hidden;
}

@media (max-width: 767px) {
    .table-overflow {
        overflow-x: scroll;
        width: calc(100vw - 100px);
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/Sessions/Sessions.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,8BAA8B;AAClC;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI;QACI,kBAAkB;QAClB,0BAA0B;IAC9B;AACJ","sourcesContent":[".panne {\n    display: block;\n    justify-content: center;\n    align-items: center;\n}\n\n.gap-2 {\n    gap: .2rem !important;\n}\n\n.mb-3 {\n    margin-bottom: 1rem !important;\n}\n\n.d-flex {\n    display: flex !important;\n}\n\n.sessions-max {\n    overflow: hidden;\n}\n\n@media (max-width: 767px) {\n    .table-overflow {\n        overflow-x: scroll;\n        width: calc(100vw - 100px);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
