// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-table {
    margin-top: 20px;
    display: block;
    overflow-x: scroll;
    max-height: 600px;
    overflow-y: scroll;
}

th {
    min-width: 150px;
}

.spinner-bis {
    display: flex;
    justify-content: center;
    align-items: center;
}

.table-overflow {
    overflow-x: scroll;
    width: calc(100vw - 100px);
}
`, "",{"version":3,"sources":["webpack://./src/pages/Inscrits/Inscrits.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,cAAc;IACd,kBAAkB;IAClB,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,0BAA0B;AAC9B","sourcesContent":[".main-table {\n    margin-top: 20px;\n    display: block;\n    overflow-x: scroll;\n    max-height: 600px;\n    overflow-y: scroll;\n}\n\nth {\n    min-width: 150px;\n}\n\n.spinner-bis {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.table-overflow {\n    overflow-x: scroll;\n    width: calc(100vw - 100px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
