// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (min-width: 768px) {
    .container {
        width: 960px !important;
        display: flex;
        margin-bottom: 10%;
    }
}

.container {
    display: flex;
    flex: 1 1;
}

.check-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
}

@media (max-width: 767px) {
    .container {
        width: 100% !important;
        display: flex;
        margin-bottom: 30%;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/Success/Success.css"],"names":[],"mappings":"AAAA;IACI;QACI,uBAAuB;QACvB,aAAa;QACb,kBAAkB;IACtB;AACJ;;AAEA;IACI,aAAa;IACb,SAAO;AACX;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI;QACI,sBAAsB;QACtB,aAAa;QACb,kBAAkB;IACtB;AACJ","sourcesContent":["@media (min-width: 768px) {\n    .container {\n        width: 960px !important;\n        display: flex;\n        margin-bottom: 10%;\n    }\n}\n\n.container {\n    display: flex;\n    flex: 1;\n}\n\n.check-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 3rem;\n}\n\n@media (max-width: 767px) {\n    .container {\n        width: 100% !important;\n        display: flex;\n        margin-bottom: 30%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
