import {ReturnTypes} from "../interfaces/returntype";
import {makeRequest} from "./makeRequests";

export const getNbrInscrits = async (id: string) => {
    const count = await makeRequest("get-count-session", JSON.stringify({"sessionId": id}), ReturnTypes.String);
    if (Array.isArray(count)) {
        return count[0];
    }
    return 0;
}
