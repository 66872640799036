import React, {FC} from "react"
import "./SecondSection.css"

const SecondSection: FC = () => {
    return <div className="second-section">
        <h1 className="secondsection-text">Ce qu'en pensent les anciens</h1>
        <video controls>
            <source src="https://iegeducation-assets.ams3.cdn.digitaloceanspaces.com/rinvindaf-temoignage-1.mp4"
                    type="video/mp4"/>
        </video>

    </div>
}

export default SecondSection;